@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-querybuilder/dist/query-builder.css';

body {
  margin: 0.5rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

tr.disabled {
  background-color: #f0f0f0d4;
}